import React from 'react';
import { Nav } from 'react-bootstrap';
import BandBanner from '../Assets/BandBanner.png'

const Home = () => {
  return (
    <section id="home">
      <img id='banner' src={BandBanner} alt='Band Banner' />
      <div id='tagline'>
        <h1>NY's 90s Alternative Rock Tribute Band</h1>
      </div>
    </section>
  );
};

export default Home;