import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-modal';

import StayTuned from '../Assets/PromoFlyers/StayTuned_Final.png';
import Feb2PROMO from '../Assets/PromoFlyers/Feb2PROMO.png';
import DuckInnApril from '../Assets/PromoFlyers/DuckInn_04052024.png';
import aprilPromo from '../Assets/PromoFlyers/420Promo.png';
import Hogs4Hope from '../Assets/PromoFlyers/Hogs4Hope.png';
import Gleasons from '../Assets/PromoFlyers/GleasonsPromo.png';
import JoeysJune from '../Assets/PromoFlyers/Joeys_06082024.png';
import DuckJune from '../Assets/PromoFlyers/Duck_06212024 copy.png';
import GleasonsJune from '../Assets/PromoFlyers/Gleasons_06292024.png';
import LaCasaJuly from '../Assets/PromoFlyers/LaCasa_Promo copy Large.png';
import RamblingJuly from '../Assets/PromoFlyers/Rambling_07.27.2024.png';
import GleasonsHalloween from '../Assets/PromoFlyers/Gleasons_Halloween.png';
import WestchesterTattooCon from '../Assets/PromoFlyers/WestchesterTattooCon Large.png';
import Lucys2024 from '../Assets/PromoFlyers/Lucys_11.2024.png';
import Stockroom02_2025 from '../Assets/PromoFlyers/Stockroom_02.2025.png'

Modal.setAppElement('#root');

const Events = () => {
  const [value, setValue] = useState(new Date());
  const [modalIsOpen, setIsOpen] = useState(false);

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const events = [
    { date: new Date('2024-02-02T20:00:00'), title: 'Live at Joey\'s Bar', image: Feb2PROMO },
    { date: new Date('2024-04-20T18:00:00'), title: '420 Fest', image: aprilPromo },
    { date: new Date('2024-07-26T21:00:00'), title: 'La Casa in Mamaroneck', image: LaCasaJuly },
    { date: new Date('2024-07-27T20:00:00'), title: 'Rambling House in The Bronx', image: RamblingJuly },
    { date: new Date('2024-10-25T20:00:00'), title: 'Halloween Show @ Gleasons', image: GleasonsHalloween },
    { date: new Date('2024-11-08T21:00:00'), title: 'The Garage at Lucys in Pleasantville', image: Lucys2024 },
    { date: new Date('2024-11-16T22:00:00'), title: 'NY Tattoo & Horror Con @Westchester County Center', image: WestchesterTattooCon },
    { date: new Date('2025-02-15T19:30:00'), title: 'Stockroom Showcase at Yonkers Brewery', image: Stockroom02_2025 },
    { date: new Date('2025-04-26T20:00:00'), title: 'Yonkers Brewery w/Evanescence Tribute', image: null },
    { date: new Date('2025-05-31T20:00:00'), title: 'Yonkers Brewery w/Blink 182 Tribute', image: null }
  ];

  const setToMidnight = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const selectedDateMidnight = setToMidnight(value);
  const eventList = events.filter(event => setToMidnight(event.date).getTime() === selectedDateMidnight.getTime());

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const eventDate = events.find(event => setToMidnight(event.date).getTime() === setToMidnight(date).getTime());
      if (eventDate) {
        return 'event-date';
      }
    }
    return null;
  };

  useEffect(() => {
    const upcomingImagesContainer = document.getElementById('eventImages');
    const upcomingImages = upcomingImagesContainer.querySelectorAll('img');
  
    if (upcomingImages.length === 1) {
      upcomingImagesContainer.classList.add('single');
    } else {
      upcomingImagesContainer.classList.remove('single');
    }
  }, []);
  

  return (
    <div id="events">
      <h2>Upcoming Events</h2>

      <Calendar class="calendar"
        onChange={onChange}
        value={value}
        onClickDay={(value) => {
          setValue(value);
          console.log('Clicked day:', value);
          openModal();
        }}
        tileClassName={tileClassName}
      />

      <Modal
        id="modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Event List"
        className="custom-modal-content"
        overlayClassName="custom-modal-overlay"
      >
        <button style={{ margin: '10px 0 20px 0' }} onClick={closeModal}>Close</button>
        <h3>Events on {value.toDateString()}</h3>
        <ul>
          {eventList.length > 0 ? (
            eventList.map((event, index) => (
              <li style={{ justifyContent: 'center' }} key={index}>
                <h4>{event.title}</h4>
                <img src={event.image} alt={event.title} style={{ width: '40%', height: '45%' }} />
              </li>
            ))
          ) : (
            <li>No events for this date.</li>
          )}
        </ul>
      </Modal>

      <div id="eventImages" className="upcoming">
      
        {/* <img id="stayTuned" src={StayTuned} alt='Stay Tuned' /> */}

        <a href="https://www.instagram.com/p/DF5qu4KvedY/?hl=en" target="_blank" rel="noopener noreferrer">
          <img src={Stockroom02_2025} alt='Stockroom Showcase' />
        </a>
        
      </div>

      <h2 id='pastEvents'>Past Events</h2>

      <div id="eventImages">
        <a href="https://bloodandinkny.com/pages/afterparty" target="_blank" rel="noopener noreferrer">
          <img src={WestchesterTattooCon} alt='WestchesterTattooCon' />
        </a>
        <a href="https://www.lucyspleasantvilleny.com/events" target="_blank" rel="noopener noreferrer">
          <img src={Lucys2024} alt='Lucys_Nov2024' />
        </a>
        <a href="https://www.gleasonspeekskill.com/events" target="_blank" rel="noopener noreferrer">
          <img src={GleasonsHalloween} alt='GleasonsHalloween' />
        </a>
        <a href="http://www.ramblinghousenyc.com/" target="_blank" rel="noopener noreferrer">
          <img src={RamblingJuly} alt='RamblingJuly' />
        </a>
        <a href="https://lacasamamaroneck.com/home" target="_blank" rel="noopener noreferrer">
          <img src={LaCasaJuly} alt='LaCasaJuly' />
        </a>
        <a href="https://www.gleasonspeekskill.com/events" target="_blank" rel="noopener noreferrer">
          <img src={GleasonsJune} alt='GleasonsJune' />
        </a>
        <a href="https://www.crazygames.com/game/duck-hunt" target="_blank" rel="noopener noreferrer">
          <img src={DuckJune} alt='DuckInn' />
        </a>
        <a href="https://www.joeyssportsbarandgrill.com/" target="_blank" rel="noopener noreferrer">
          <img src={JoeysJune} alt='JoeysJune' />
        </a>
        <a href="https://www.gleasonspeekskill.com/events" target="_blank" rel="noopener noreferrer">
          <img src={Gleasons} alt='GleasonsMay' />
        </a>
        <img src={Hogs4Hope} alt='Hogs4Hope' />
        <a href="https://www.crazygames.com/game/duck-hunt" target="_blank" rel="noopener noreferrer">
          <img src={DuckInnApril} alt='DuckInn' />
        </a>
        <a href="http://www.ramblinghousenyc.com/" target="_blank" rel="noopener noreferrer">
          <img src={aprilPromo} alt='AprilPromo' />
        </a>
        <a href="https://www.joeyssportsbarandgrill.com/" target="_blank" rel="noopener noreferrer">
          <img src={Feb2PROMO} alt='FebPromo' />
        </a>
      </div>

    </div>
  );
};

export default Events;