import React, { useState, useEffect } from 'react';
import { storage } from '../firebaseConfig';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import LogoBanner from '../Assets/Gallery/LogoBanner.png'
import HogBand from '../Assets/Gallery/Hogs_BandPhoto.png'
import HogGav from '../Assets/Gallery/Hogs_Gav&Shane.png'
import HogCrowd from '../Assets/Gallery/Hogs_Crowd.png'
import HogPete from '../Assets/Gallery/Hogs_Pete.png'
import Band2 from '../Assets/Gallery/BandB&W.png'
import Bass from '../Assets/Gallery/Bass.png'
import Pete from '../Assets/Gallery/Pete.png'
import XFiles from '../Assets/Gallery/XFiles.png'
import Cassettes from '../Assets/Gallery/Cassettes.png'
import Simpsons from '../Assets/Gallery/WITB_Simpsons_FINAL.png'
import TMNT from '../Assets/Gallery/TMNT.png'
import { Carousel } from 'react-bootstrap'
import '../CSS/Gallery.css';


const Gallery = () => {
  const imageUrls = [LogoBanner, HogCrowd, HogPete, Cassettes, HogGav, HogBand, Simpsons, Pete, Bass, TMNT, Band2, XFiles];

  return (
    <div id="gallery">
      <Carousel interval={3000}> {/* Carousel will auto-scroll every 3 seconds */}
        {imageUrls.map((url, index) => (
          <Carousel.Item key={index}>
            <img
              className="gallery-image"
              src={url}
              alt={`Gallery ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Gallery;